/*
 * main app javascript
 * @TODO: migrate to Typescript
 */
;(function($, window, document, undefined) {
  'use strict';

  var $ripple = $('.btn__ripple');

  $ripple.on('click', function(e) {

    var $this = $(this);
    var $offset = $this.parent().offset();
    var $circle = document.createElement( "span" );
    $circle.className = 'btn-circle';
    $circle = $($circle);
    $this.append($circle);

    var x = e.pageX - $offset.left;
    var y = e.pageY - $offset.top;

    $circle.css({
      top: y + 'px',
      left: x + 'px'
    });
    $circle.addClass('is-active');

  });

  $ripple.on('animationend webkitAnimationEnd oanimationend MSAnimationEnd', function(e) {
  	$(this).find('is-active').removeClass('is-active');
  });

})(jQuery, window, document);

/*
 * calling function to initiate plugins on DOM ready
*/
function initPlugins() {
  // initiate popover and tooltip
  console.log('app init');
  $('[data-bs-toggle="popover"]').popover();
  $('[data-bs-toggle="tooltip"]').tooltip()
}
$(window).on( "load", function() {
  initPlugins();
});
